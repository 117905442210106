import React from 'react';
import styled, {css} from 'styled-components';

import {media} from 'web-app/styleguide/utils';
import {hexToRGBString} from 'web-app/util/hex-to-rgb';
import {Flex} from 'web-app/react/components/layout/layout';
import PlatformHelper from 'web-app/helpers/platform-helper';
import {Caption} from 'web-app/react/components/text/text';
import {getSpacing, s1, s2, s6, s8, s10, s12, s15, s16} from 'web-app/styleguide/spacing';
import {EspiraIcon} from 'web-app/react/components/icons/custom';
import {ESPIRA_ICON_CONFIG, Whitelabel} from 'web-app/shared/white-labeling-constants';
import {getWhitelabel} from 'web-app/shared/white-labeling-helpers';
import {exhaustiveCheck} from 'web-app/util/typescript';

const LoginChrome: React.FC<
    React.PropsWithChildren<{locale?: string; hasAccounts?: boolean; loadingDeviceAccounts?: boolean}>
> = ({children, locale, hasAccounts, loadingDeviceAccounts}) => {
    const whitelabel = React.useMemo(() => getWhitelabel(), []);

    return (
        <LayoutContainer>
            <Before>
                <InnerContent>
                    <Flex justify="center" align="center" shrink={0}>
                        <Logo whitelabel={whitelabel} hasAccounts={hasAccounts} />
                    </Flex>
                    {children}
                </InnerContent>
                {PlatformHelper.isMobileApp() ? (
                    <BottomContainer>
                        <Caption secondary>{PlatformHelper.version()}</Caption>
                    </BottomContainer>
                ) : null}
            </Before>
            <After>
                <Placeholder locale={locale} isStaff={hasAccounts} loading={loadingDeviceAccounts} />
            </After>
        </LayoutContainer>
    );
};

const Logo: React.FC<React.PropsWithChildren<{whitelabel: Whitelabel | null; hasAccounts?: boolean}>> = ({
    whitelabel,
    hasAccounts,
}) => {
    switch (whitelabel) {
        case Whitelabel.espira:
            return (
                <StyledEspiraIconContainer hasAccounts={hasAccounts}>
                    <StyledEspiraIcon
                        width={144}
                        height={140}
                        spireTextColor={ESPIRA_ICON_CONFIG.SPIRE_PORTALEN_COLOR_GREEN}
                        portalenTextColor={ESPIRA_ICON_CONFIG.SPIRE_PORTALEN_COLOR_RED}
                        hasAccounts={hasAccounts}
                    />
                </StyledEspiraIconContainer>
            );
        case Whitelabel.kido:
            return <StyledKidoLogo src="img/kido/kido-blue.png" />;
        case Whitelabel.brighthorizons:
            return <StyledBrightHorizonsLogo hasAccounts={hasAccounts} src="img/bright-horizons/login-logo.svg" />;
        case Whitelabel.kindred:
            return <StyledKindredLogo hasAccounts={hasAccounts} src="img/kindred/logo-white-bg.svg" />;
        case Whitelabel.monkeypuzzle:
            return <StyledMonkeyPuzzleLogo src="img/monkeypuzzle/logo.svg" />;
        case Whitelabel.grandiruk:
            return <StyledGrandirUKLogo src="img/grandiruk/GrandirLogo-WhiteBG.svg" />;
        case Whitelabel.nuffieldhealth:
            return <StyledNuffieldHealthLogo src="img/nuffieldhealth/NuffieldLogo-WhiteBG.svg" />;
        case Whitelabel.nfamilyclub:
            return <StyledNFamilyClubLogo src="img/nfamilyclub/logo-white-bg.svg" />;
        case Whitelabel.kinderzimmeruk:
            return <StyledKinderzimmerUKLogo src="img/kinderzimmeruk/KinderzimmerUKLogoMark.svg" />;
        case Whitelabel.cpctreehouse:
            return <StyledCpcTreehouseLogo src="img/cpc-treehouse/CpcTreehouseLogoMark.svg" />;
        case Whitelabel.cpcorchards:
            return <StyledCpcOrchardLogo src="img/cpc-orchard/CpcOrchardLogoMark.svg" />;
        case Whitelabel.busybees:
            return <StyledBusyBeesLogo hasAccounts={hasAccounts} src="img/busybees/BusyBeesLogo.svg" />;
        case Whitelabel.bbmontessori:
            return <StyledBBMontessoriLogo src="img/bbmontessori/logo-white-bg.svg" />;
        case Whitelabel.bbodyssey:
            return <StyledBBOdysseyLogo src="img/bbodyssey/logo-white-bg.svg" />;
        case null:
            return <StyledFamlyLogo src="img/famly-logos/Famly-Logo-WhiteBG.svg" />;
        default:
            exhaustiveCheck(whitelabel);
            return <StyledFamlyLogo src="img/famly-logos/Famly-Logo-WhiteBG.svg" />;
    }
};

const StyledEspiraIconContainer = styled.div<{hasAccounts?: boolean}>`
    display: ${props => (props.hasAccounts ? 'none' : 'flex')};
    margin-bottom: ${props => (props.hasAccounts ? getSpacing(s2) : getSpacing(s8))};
    @media (min-height: 600px) {
        display: flex;
    }

    @media (min-height: 850px) {
        margin-top: ${props => (props.hasAccounts ? '0' : getSpacing(s15))};
        margin-bottom: ${getSpacing(s10)};
    }
`;

const StyledEspiraIcon = styled(EspiraIcon)<{hasAccounts?: boolean}>`
    width: ${props => (props.hasAccounts ? '90px' : '160px')};
    height: ${props => (props.hasAccounts ? '80px' : '157px')};

    @media (min-height: 850px) {
        width: ${props => (props.hasAccounts ? '140px' : '200px')};
        height: ${props => (props.hasAccounts ? '130px' : '190px')};
    }
`;

const StyledKidoLogo = styled.img`
    margin-bottom: ${getSpacing(s16)};
    ${media.tablet`
        margin-bottom: ${getSpacing(s6)};
        @media screen and (min-aspect-ratio: 1024/530) {
            width: 130px;
        }
    `}
    ${media.mobile`&& {
        margin-bottom: ${getSpacing(s6)};
        height: 35px;
    }`}
    ${media.portrait`
        margin-bottom: ${getSpacing(s12)};
    `}
    ${media.notLandscape`
        @media (max-height: 900px) {
            margin-bottom: ${getSpacing(s8)};
        }
        @media (max-height: 600px) {
            height: 30px;
            width: auto;
            margin-bottom: ${getSpacing(s2)};
        }
    `}
`;

const StyledFamlyLogo = styled.img`
    width: 250px;
    margin-bottom: ${getSpacing(s16)};

    ${media.mobile`&& {
        margin-bottom: ${getSpacing(s6)};
        width: 220px;
    }`}
`;

const StyledBrightHorizonsLogo = styled.img<{hasAccounts?: boolean}>`
    height: ${props => (props.hasAccounts ? '100px' : '150px')};
    width: auto;

    @media (min-height: 850px) {
        height: 192px;
        margin-bottom: ${getSpacing(s16)};
    }
`;

const StyledKindredLogo = styled.img<{hasAccounts?: boolean}>`
    height: ${props => (props.hasAccounts ? '50px' : '80px')};
    width: auto;
    margin-bottom: ${props => (props.hasAccounts ? '24px' : '32px')};

    @media (min-height: 850px) {
        height: 125px;
        margin-bottom: ${getSpacing(s16)};
    }
`;

const StyledMonkeyPuzzleLogo = styled.img`
    height: 100px;
    width: auto;
    margin-bottom: 24px;
`;

const StyledGrandirUKLogo = styled.img`
    height: 40px;
    width: auto;
    margin-bottom: ${getSpacing(s8)};

    @media (min-height: 850px) {
        height: 64px;
        margin-bottom: ${getSpacing(s16)};
    }

    @media (max-width: 700px) {
        margin-top: 0;
    }
`;

const StyledNuffieldHealthLogo = styled.img`
    height: 80px;
    width: auto;
    margin-bottom: ${getSpacing(s8)};

    @media (min-height: 850px) {
        height: 100px;
        margin-bottom: ${getSpacing(s16)};
    }
`;

const StyledNFamilyClubLogo = styled.img`
    height: 80px;
    width: auto;
    margin-bottom: 32px;

    @media (min-height: 850px) {
        height: 155px;
        margin-bottom: ${getSpacing(s16)};
    }
`;

const StyledKinderzimmerUKLogo = styled.img`
    height: 70px;
    width: auto;
    margin-bottom: ${props => props.theme.mf.spacing(8)};

    @media (min-height: 850px) {
        height: 155px;
        margin-bottom: ${props => props.theme.mf.spacing(6)};
    }
`;

const StyledCpcTreehouseLogo = styled.img`
    height: 84px;
    width: auto;
    margin-bottom: ${props => props.theme.mf.spacing(4)};

    @media (min-height: 850px) {
        height: 155px;
        margin-bottom: ${props => props.theme.mf.spacing(10)};
    }
`;

const StyledCpcOrchardLogo = styled.img`
    height: 84px;
    width: auto;
    margin-bottom: ${props => props.theme.mf.spacing(4)};

    @media (min-height: 850px) {
        height: 180px;
        margin-bottom: ${props => props.theme.mf.spacing(10)};
    }
`;

const StyledBBMontessoriLogo = styled.img<{hasAccounts?: boolean}>`
    height: ${props => (props.hasAccounts ? '40px' : '60px')};
    width: auto;
    margin-bottom: ${props => props.theme.mf.spacing(6)};

    @media (min-height: 850px) {
        height: 80px;
        margin-bottom: ${props => props.theme.mf.spacing(10)};
    }
`;

const StyledBBOdysseyLogo = styled.img<{hasAccounts?: boolean}>`
    height: ${props => (props.hasAccounts ? '40px' : '60px')};
    width: auto;
    margin-bottom: ${props => props.theme.mf.spacing(8)};

    @media (min-height: 850px) {
        height: 80px;
        margin-bottom: ${getSpacing(s16)};
    }
`;

const StyledBusyBeesLogo = styled.img<{hasAccounts: boolean | undefined}>`
    height: 84px;
    width: auto;
    margin-bottom: ${props => props.theme.mf.spacing(4)};

    @media (min-height: 850px) {
        height: ${props => (props.hasAccounts ? 100 : 140)}px;
        margin-bottom: ${props => props.theme.mf.spacing(10)};
    }

    @media (min-height: 1000px) {
        height: 140px;
        margin-bottom: ${props => props.theme.mf.spacing(10)};
    }
`;

export default LoginChrome;

const BeforePosition = css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 40%;
`;

const BottomContainer = styled(Flex)`
    position: absolute;

    right: 0px;
    bottom: 0px;

    padding-right: ${getSpacing(s1)};
    padding-bottom: calc(${getSpacing(s1)} + env(safe-area-inset-bottom));

    font-style: italic;
`;

const InnerContent = styled('div')`
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;
    width: 100%;
    justify-content: flex-start;
    box-sizing: border-box;
    .slick-list {
        overflow: visible;
    }
    ${media.tablet`
        width: 100%;
        .slick-list {
            overflow: hidden;
        }
    `}
`;

const LayoutContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${props => props.theme.invertedText};
    overflow-x: hidden;
`;
const Before = styled.div`
    flex-shrink: 0;
    background: ${props => props.theme.invertedText};
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    ${media.tablet`
        right: 0;
    `}
    ${media.mobile`
        display: block;
        &:before {
            display: none;
        }
    `}
    ${BeforePosition}
`;
const After = styled.div`
    overflow: hidden;
    background: ${props => props.theme.backgroundHover};
    flex-shrink: 0;
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -3%;
    left: 60%;
    ${media.tablet`
        display: none;
    `}
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 2;
        box-shadow: 8px 0 16px 0 inset rgba(${props => hexToRGBString(props.theme.text)}, 0.1);
    }
`;

class Placeholder extends React.Component<{locale?: string; isStaff?: boolean; loading?: boolean}> {
    get whitelabel() {
        return getWhitelabel();
    }

    get image() {
        if (!this.props.locale) {
            return undefined;
        }

        switch (this.whitelabel) {
            case Whitelabel.espira:
                return 'url(/img/espira/Espira_login_background.jpg)';
            case Whitelabel.kido:
                return 'url(/img/kido/kido-login-background.jpg)';
            case Whitelabel.brighthorizons:
                return 'url(/img/bright-horizons/login-background.jpg)';
            case Whitelabel.monkeypuzzle:
                return 'url(/img/monkeypuzzle/login.png)';
            case Whitelabel.grandiruk:
                return 'url(/img/grandiruk/login.jpg)';
            case Whitelabel.nuffieldhealth:
                return 'url(/img/nuffieldhealth/login.jpg)';
            case Whitelabel.nfamilyclub:
                return 'url(/img/nfamilyclub/login.jpg)';
            case Whitelabel.kindred:
                return 'url(/img/kindred/login.jpg)';
            case Whitelabel.kinderzimmeruk:
                return 'url(/img/kinderzimmeruk/login.jpg)';
            case Whitelabel.cpctreehouse:
                return 'url(/img/cpc-treehouse/login.jpg)';
            case Whitelabel.cpcorchards:
                return 'url(/img/cpc-orchard/login.jpg)';
            case Whitelabel.busybees:
                return 'url(/img/busybees/login.jpg)';
            case Whitelabel.bbmontessori:
                return 'url(img/bbmontessori/login.jpg)';
            case Whitelabel.bbodyssey:
                return 'url(img/bbodyssey/login.jpg)';

            default:
                switch (this.props.locale) {
                    case 'de_DE':
                    case 'de_CH':
                        return 'url(img/login/background_de.jpg)';
                    case 'da_DK':
                        return 'url(img/login/background_dk.jpg)';
                    case 'en_GB':
                        if (this.props.loading) {
                            return undefined;
                        }
                        return 'url(img/login/background_gb.jpg)';
                    default:
                        return 'url(img/login/background_gb.jpg)';
                }
        }
    }
    public render() {
        return <PlaceholderImage whitelabel={this.whitelabel} locale={this.props.locale} image={this.image} />;
    }
}

interface PlaceholderImageProps {
    locale?: string;
    image?: string;
    whitelabel: Whitelabel | null;
}
const placeHolderImagePosition = (props: PlaceholderImageProps) => {
    switch (props.whitelabel) {
        case Whitelabel.espira:
            return '65% center';
        case Whitelabel.kido:
            return '50% center';
        case Whitelabel.brighthorizons:
            return '70% center';
        case Whitelabel.kindred:
            return '10% center';
        case Whitelabel.monkeypuzzle:
            return '50% center';
        case Whitelabel.nfamilyclub:
            return '50% center';
        case Whitelabel.grandiruk:
            return '50% center';
        case Whitelabel.nuffieldhealth:
            return '50% center';
        case Whitelabel.kinderzimmeruk:
            return '43% center';
        case Whitelabel.cpctreehouse:
            return 'right';
        case Whitelabel.cpcorchards:
            return '40% center';
        case Whitelabel.busybees:
            return '40% center';
        case Whitelabel.bbmontessori:
            return '10% center';
        case Whitelabel.bbodyssey:
            return '10% bottom';
        default:
            switch (props.image) {
                case 'url(img/login/background_de.jpg)':
                case 'url(img/login/background_dk.jpg)':
                    return '25% center';
                case 'url(img/login/background_gb.jpg)':
                    return '35% center';
                default:
                    return '50% center';
            }
    }
};
const PlaceholderImage = styled('div')<PlaceholderImageProps>`
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: -6%;
    right: -10px;
    background: ${props => props.image ?? props.theme.invertedText};
    background-size: cover;
    background-position: ${placeHolderImagePosition};
    background-repeat: no-repeat;
`;
