import i18next from 'i18next';

import RouteConfig, {withPath} from 'web-app/react/routes/route-config';
import {needsTranslations, TranslationDomain} from 'web-app/util/scoped-needs-translation';
import {type AtLeastOne, exhaustiveCheck} from 'web-app/util/typescript';

import * as Types from '../types';

export interface PageDescription {
    description?: string;
    helpArticleHref?: string;
    parentDescription?: string;
    parentHelpArticleHref?: string;
}

interface NavigationLinkDetails {
    title: string;
    pageDescription?: PageDescription;
    topBarTitle?: string;
    mobileTitle?: string;
    // ... more ...
}

export const getNavigationLinkDetails = (navigationLinkId: Types.NavigationLinkIds): NavigationLinkDetails => {
    switch (navigationLinkId) {
        // --- Navigation group: Get Started ---
        case Types.NavigationLinkIds.NURSERY_INFO:
            return {
                title: i18next.t('navigationV2.titles.nurseryInfo'),
            };
        case Types.NavigationLinkIds.ROOMS:
            return {
                title: i18next.t('navigationV2.titles.getStartedRooms'),
            };
        case Types.NavigationLinkIds.MANAGE_GROUP_RULES:
            return {
                title: i18next.t('navigationV2.titles.getStartedManageGroupRules'),
            };
        case Types.NavigationLinkIds.GET_STARTED_CHILDREN:
        case Types.NavigationLinkIds.CHILD_IMPORT:
            return {
                title: i18next.t('navigationV2.titles.getStartedChildren'),
            };
        case Types.NavigationLinkIds.GET_STARTED_STAFF:
        case Types.NavigationLinkIds.STAFF_ROLES:
        case Types.NavigationLinkIds.STAFF_PERMISSIONS:
            return {
                title: i18next.t('navigationV2.titles.getStartedStaff'),
            };
        case Types.NavigationLinkIds.INVOICING_PROFILES:
            return {
                title: i18next.t('navigationV2.titles.getStartedInvoicingProfiles'),
            };
        case Types.NavigationLinkIds.AUTOMATIC_INVOICING_CONFIG:
            return {
                title: i18next.t('automaticInvoicing.getStartedHeader'),
            };
        case Types.NavigationLinkIds.FINANCE:
            return {
                title: i18next.t('navigationV2.titles.overview'),
            };
        case Types.NavigationLinkIds.ATTENDANCE_SCHEDULES:
            return {
                title: i18next.t('navigationV2.titles.getStartedAttendancesSchedules'),
            };
        case Types.NavigationLinkIds.FUNDING_GRANTS:
            return {
                title: i18next.t('navigationV2.titles.getStartedFundingGrants'),
            };
        case Types.NavigationLinkIds.PRICING:
            return {
                title: i18next.t('navigationV2.titles.getStartedPricing'),
            };
        case Types.NavigationLinkIds.DISCOUNT_SETTINGS:
            return {
                title: i18next.t('navigationV2.titles.getStartedDiscountSettings'),
            };
        case Types.NavigationLinkIds.GET_STARTED_PLANS:
            return {
                title: i18next.t('navigationV2.titles.getStartedPlans'),
            };
        case Types.NavigationLinkIds.GET_STARTED_FAMLY_PAY:
        case Types.NavigationLinkIds.GET_STARTED_FAMLY_PAY_SETTINGS:
            return {
                title: i18next.t('navigationV2.titles.getStartedFamlyPay'),
            };
        case Types.NavigationLinkIds.INVOICE_LAYOUT:
            return {
                title: i18next.t('navigationV2.titles.getStartedInvoiceLayout'),
            };
        // --- Navigation group: Home ---
        case Types.NavigationLinkIds.OVERVIEW:
        case Types.NavigationLinkIds.CHILDREN_AND_STAFF:
            return {
                title: i18next.t('navigationV2.titles.overview'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.overview'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.overview'),
                },
            };
        case Types.NavigationLinkIds.HOME_CUSTOMIZATION:
            return {
                title: i18next.t('navigationV2.titles.customizeOverview'),
            };
        case Types.NavigationLinkIds.ORGANIZATION_SETTINGS:
            return {
                title: i18next.t('navigationV2.titles.settings'),
            };
        case Types.NavigationLinkIds.OVERVIEW_REPORTS:
            return {
                title: i18next.t('navigationV2.titles.reports'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.reports'),
                },
            };
        case Types.NavigationLinkIds.NEWS_FEED:
        case Types.NavigationLinkIds.NEWS_FEED_SINGLE_POST:
            return {
                title: i18next.t('navigationV2.titles.newsFeed'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.newsFeed'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.newsFeed'),
                    parentDescription: i18next.t('navigationV2.parentDescriptions.newsFeed'),
                    parentHelpArticleHref: i18next.t('navigationV2.parentHelpArticleHrefs.newsFeed'),
                },
            };
        case Types.NavigationLinkIds.CHILDREN:
            return {
                title: i18next.t('navigationV2.titles.childrenSafeguarding'),
            };
        case Types.NavigationLinkIds.CHILDREN_OVERVIEW:
            return {
                topBarTitle: i18next.t('navigationV2.titles.childrenOverview'),
                title: i18next.t('navigationV2.titles.overview'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.children'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.children'),
                },
            };
        case Types.NavigationLinkIds.PARENTAL_PERMISSIONS:
        case Types.NavigationLinkIds.PARENTAL_PERMISSIONS_CHECK_PERMISSIONS:
            return {
                title: i18next.t('navigationV2.titles.permissions'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.checkParentalPermissions'),
                },
            };
        case Types.NavigationLinkIds.VACATION_PLANS:
            return {
                title: i18next.t('navigationV2.titles.holidayRsvp'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.vacationPlans'),
                },
            };
        case Types.NavigationLinkIds.STAFF:
            return {
                title: i18next.t('navigationV2.titles.staff'),
                pageDescription: {
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.staff'),
                    description: i18next.t('navigationV2.descriptions.staff'),
                },
            };
        case Types.NavigationLinkIds.DOCUMENTS:
            return {
                title: i18next.t('navigationV2.titles.documents'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.documents'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.documents'),
                },
            };
        case Types.NavigationLinkIds.PARENT_DOCUMENTS:
            return {
                title: i18next.t('navigationV2.titles.documents'),
            };
        case Types.NavigationLinkIds.CALENDAR:
        case Types.NavigationLinkIds.CALENDAR_EVENT:
            return {
                title: i18next.t('navigationV2.titles.calendar'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.calendar'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.calendar'),
                    parentDescription: i18next.t('navigationV2.parentDescriptions.calendar'),
                    parentHelpArticleHref: i18next.t('navigationV2.parentHelpArticleHrefs.calendar'),
                },
            };
        case Types.NavigationLinkIds.OLD_MEAL_PLANS:
        case Types.NavigationLinkIds.MEAL_PLANS_V3:
        case Types.NavigationLinkIds.MEAL_PLANS_V3_CREATE:
        case Types.NavigationLinkIds.MEAL_PLANS_V3_VIEW:
        case Types.NavigationLinkIds.MEAL_SETTINGS:
            return {
                title: i18next.t('navigationV2.titles.mealPlans'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.mealPlans'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.mealPlans'),
                },
            };
        case Types.NavigationLinkIds.MEAL_BOOKINGS:
            return {
                title: i18next.t('meals.mealBookings.navigation.bookingsTitle'),
                pageDescription: {
                    description: i18next.t('meals.mealBookings.navigation.bookingsDescription'),
                },
            };
        case Types.NavigationLinkIds.MEALS:
            return {
                title: i18next.t('react.navigationV2.config.translations.json.meals'),
            };
        case Types.NavigationLinkIds.BILL_PAYER:
            return {
                title: i18next.t('navigationV2.titles.balance'),
                pageDescription: {
                    parentDescription: i18next.t('navigationV2.parentDescriptions.billPayer'),
                },
            };
        case Types.NavigationLinkIds.BILL_PAYERS:
            return {
                title: i18next.t('navigationV2.titles.balances'),
                pageDescription: {
                    parentDescription: i18next.t('navigationV2.parentDescriptions.billPayer'),
                },
            };
        case Types.NavigationLinkIds.LESSON_PLANS_PARENT:
            return {
                title: i18next.t('navigationV2.titles.activityPlans'),
                pageDescription: {
                    parentHelpArticleHref: i18next.t('navigationV2.parentHelpArticleHrefs.lessonPlans'),
                    parentDescription: i18next.t('navigationV2.parentDescriptions.lessonPlans'),
                },
            };
        // --- Navigation group: Registers ---
        case Types.NavigationLinkIds.ROOM_PLANNING:
            return {
                title: i18next.t('navigationV2.titles.roomPlanning'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.roomPlanning'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.roomPlanning'),
                },
            };
        case Types.NavigationLinkIds.ATTENDANCE_REPORT:
            return {
                title: i18next.t('navigationV2.titles.attendanceReport'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.attendanceReport'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.attendanceReport'),
                },
            };
        case Types.NavigationLinkIds.STAFFING:
            return {
                title: i18next.t('navigationV2.titles.staffing'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.staffing'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.staffing'),
                },
            };
        case Types.NavigationLinkIds.STAFF_RATIO_REPORT:
            return {
                title: i18next.t('navigationV2.titles.staffRatioReport'),
                topBarTitle: i18next.t('navigationV2.titles.staffRatioReport'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.staffRatioReport'),
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_ATTENDANCE:
            return {
                title: i18next.t('navigationV2.titles.staffAttendanceBeta'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.staffAttendance'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.staffAttendance'),
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_SCHEDULE_V2:
            return {
                title: i18next.t('navigationV2.titles.shiftPlanner'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.shiftPlanner'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.shiftPlanner'),
                },
            };
        case Types.NavigationLinkIds.STAFF_ATTENDANCE_DETAILS:
            return {
                title: i18next.t('navigationV2.titles.staffAttendanceDetails'),
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_HOURS:
            return {
                title: i18next.t('navigationV2.titles.staffHours'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.staffHours'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.staffHours'),
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_HOURS_V2:
            return {
                title: i18next.t('navigationV2.titles.staffHoursV2'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.staffHours'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.staffHours'),
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_LEAVE:
            return {
                title: i18next.t('staffRotas.leaveRequests.title'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.staffLeave'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.staffLeave'),
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_LEAVE_V2:
            return {
                title: i18next.t('react.staffingAttendance.staffLeaveV2.translations.json.staffLeavePage.title'),
                pageDescription: {
                    description: i18next.t(
                        'react.staffingAttendance.staffLeaveV2.translations.json.staffLeavePage.description',
                    ),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.staffLeave'),
                },
            };
        case Types.NavigationLinkIds.INQUIRIES_V2:
            return {
                title: i18next.t('navigationV2.titles.inquiries'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.inquiriesV2'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.inquiriesV2'),
                },
            };
        case Types.NavigationLinkIds.OCCUPANCY:
            return {
                title: i18next.t('navigationV2.titles.occupancyReport'),
                topBarTitle: i18next.t('navigationV2.titles.occupancyReport'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.occupancy'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.occupancy'),
                },
            };
        case Types.NavigationLinkIds.OCCUPANCY_INDEX:
            return {
                title: i18next.t('navigationV2.titles.occupancyReportName'),
                topBarTitle: i18next.t('navigationV2.titles.occupancyReportName'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.occupancy'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.occupancy'),
                },
            };
        case Types.NavigationLinkIds.ATTENDANCE_STATISTICS:
            return {
                title: i18next.t('navigationV2.titles.attendanceStatistics'),
                topBarTitle: i18next.t('navigationV2.titles.attendanceStatistics'),
            };
        case Types.NavigationLinkIds.OCCUPANCY_PLANNER:
            return {
                title: i18next.t('navigationV2.titles.occupancyPlanner'),
                topBarTitle: i18next.t('navigationV2.titles.occupancyPlanner'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.occupancy'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.occupancy'),
                },
            };
        case Types.NavigationLinkIds.FORMS:
        case Types.NavigationLinkIds.CRF_NEW_FORM:
        case Types.NavigationLinkIds.FORM_SUBMISSION_RESPONSE:
        case Types.NavigationLinkIds.FORM_BUILDER:
            return {
                title: i18next.t('navigationV2.titles.forms'),
                topBarTitle: i18next.t('navigationV2.titles.forms'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.forms'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.forms'),
                },
            };
        // --- Navigation group: Learning ---
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT:
            return {
                title: i18next.t('navigationV2.titles.childDevelopment'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.childDevelopment'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.childDevelopment'),
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_OVERVIEW:
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_GROUP_OBSERVATION:
            return {
                title: i18next.t('navigationV2.titles.overview'),
                topBarTitle: i18next.t('navigationV2.titles.childDevelopment'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.childDevelopment'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.childDevelopment'),
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_NEXT_STEPS:
            return {
                title: i18next.t('navigationV2.titles.whatsNext'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.nextSteps'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.childDevelopment'),
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_PROGRESS_REPORT:
            return {
                title: i18next.t('navigationV2.titles.progressReport'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.progressReport'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.childDevelopment'),
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_COMPARE_COHORTS:
            return {
                title: i18next.t('navigationV2.titles.compareCohorts'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.childDevelopment'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.childDevelopment'),
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_TALLY:
            return {
                title: i18next.t('navigationV2.titles.tally'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.tally'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.childDevelopment'),
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_TWO_YEAR_CHECKS:
            return {
                title: i18next.t('navigationV2.titles.twoYearChecks'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.twoYearChecks'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.childDevelopment'),
                },
            };
        case Types.NavigationLinkIds.ACTIVITY_LIBRARY:
        case Types.NavigationLinkIds.ACTIVITY_LIBRARY_SINGLE_LIBRARY:
        case Types.NavigationLinkIds.ACTIVITY_LIBRARY_SINGLE_ACTIVITY:
            return {
                title: i18next.t('navigationV2.titles.activityLibrary'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.activityLibrary'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.activityLibrary'),
                },
            };
        case Types.NavigationLinkIds.LESSON_PLANS:
            return {
                title: i18next.t('navigationV2.titles.activityPlanner'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.lessonPlans'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.lessonPlans'),
                    parentHelpArticleHref: i18next.t('navigationV2.parentHelpArticleHrefs.lessonPlans'),
                    parentDescription: i18next.t('navigationV2.parentDescriptions.lessonPlans'),
                },
            };
        case Types.NavigationLinkIds.CURRICULUM_BUILDER_LIST:
            return {
                title: i18next.t('childDevelopment.curriculums.builder.builderTitle'),
                pageDescription: {
                    description: i18next.t('childDevelopment.curriculums.navigation.description'),
                    helpArticleHref: i18next.t('childDevelopment.curriculums.navigation.helpArticle'),
                },
            };
        case Types.NavigationLinkIds.CURRICULUM_BUILDER_CREATE:
        case Types.NavigationLinkIds.CURRICULUM_BUILDER_DUPLICATE:
        case Types.NavigationLinkIds.CURRICULUM_BUILDER_SHOW:
            return {
                title: i18next.t('childDevelopment.curriculums.builder.builderTitle'),
            };
        // --- Navigation group: Safeguarding ---
        case Types.NavigationLinkIds.ACCIDENTS_INCIDENTS:
            return {
                title: i18next.t('navigationV2.titles.accidentsIncidents'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.accidentsIncidents'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.accidentsIncidents'),
                },
            };
        case Types.NavigationLinkIds.MEDICATION_FORMS:
            return {
                title: i18next.t('navigationV2.titles.medicationForms'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.medicationForms'),
                },
            };
        case Types.NavigationLinkIds.IMMUNIZATION:
        case Types.NavigationLinkIds.IMMUNIZATION_ONBOARDING:
            return {
                title: i18next.t('navigationV2.titles.immunization'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.immunization'),
                    helpArticleHref: needsTranslations(
                        TranslationDomain.ImmunizationOnboarding,
                        'https://help.famly.co/en/articles/6955991-immunization-records',
                    ),
                },
            };
        case Types.NavigationLinkIds.HEAD_COUNT:
            return {
                title: i18next.t('navigationV2.titles.headcount'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.headcount'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.headcount'),
                },
            };
        // --- Navigation group: Finance ---
        case Types.NavigationLinkIds.REVENUE:
            return {
                title: i18next.t('navigationV2.titles.revenue'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.revenue'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.revenue'),
                },
            };
        case Types.NavigationLinkIds.DEBT:
            return {
                title: i18next.t('navigationV2.titles.debt'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.debt'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.debt'),
                },
            };
        case Types.NavigationLinkIds.PAYMENTS:
            return {
                title: i18next.t('navigationV2.titles.payments'),
            };
        case Types.NavigationLinkIds.INVOICES_REPORT:
            return {
                title: i18next.t('navigationV2.titles.invoicesReport'),
            };
        case Types.NavigationLinkIds.BILL_PAYERS_REPORT:
            return {
                title: i18next.t('navigationV2.titles.billPayersReport'),
            };
        case Types.NavigationLinkIds.DEPOSITS_REPORT:
            return {
                title: i18next.t('navigationV2.titles.depositsReport'),
            };
        case Types.NavigationLinkIds.DIRECT_DEBITS_REPORT:
            return {
                title: i18next.t('plans.debt.directDebitsFiles'),
            };
        case Types.NavigationLinkIds.INVOICING_REPORTS:
            return {
                title: i18next.t('navigationV2.titles.reports'),
                pageDescription: {},
            };
        case Types.NavigationLinkIds.INVOICING:
            return {
                title: i18next.t('navigationV2.titles.invoicing'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.invoicing'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.invoicing'),
                },
            };
        case Types.NavigationLinkIds.PUBLIC_FUNDING:
            return {
                title: i18next.t('navigationV2.titles.publicFunding'),
            };
        case Types.NavigationLinkIds.FUNDING_SETTINGS:
            return {
                title: i18next.t('institution.fundingSettings'),
            };
        case Types.NavigationLinkIds.CHILD_BASED_FUNDING:
            return {
                title: i18next.t('navigationV2.titles.childBasedFunding'),
            };
        case Types.NavigationLinkIds.FAMLY_PAY:
            return {
                title: i18next.t('navigationV2.titles.famlyPay'), // TODO: Any white labeling concerns here?
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.famlyPay'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.famlyPay'),
                },
            };
        case Types.NavigationLinkIds.BULK_IMPORT_PAYMENT:
            return {
                title: i18next.t('navigationV2.titles.bulkImportPayment'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.bulkImportPayment'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.paymentsImport'),
                },
            };
        case Types.NavigationLinkIds.BULK_IMPORT_PAYMENT_PROTOPAYMENTS_TABLE:
            return {
                title: i18next.t('navigationV2.titles.bulkImportPayment'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.bulkImportPayment'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.paymentsImport'),
                },
            };
        case Types.NavigationLinkIds.BOOKINGS:
            return {
                title: i18next.t('navigationV2.titles.bookings'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.bookings'),
                },
            };
        case Types.NavigationLinkIds.PLANS:
            return {
                title: i18next.t('bookingsApp.managePlansTab'),
            };
        case Types.NavigationLinkIds.PURCHASES:
            return {
                title: i18next.t('bookingsApp.purchasesTab'),
            };
        case Types.NavigationLinkIds.BOOKING_PATTERN_BUILDER:
            return {
                title: i18next.t('bookingPatternBuilder.title'),
                topBarTitle: i18next.t('bookingPatternBuilder.title'),
            };
        case Types.NavigationLinkIds.BULK_PATTERN_FLOW:
            return {
                title: i18next.t('bulkPatternFlow.plans'),
                topBarTitle: i18next.t('bulkPatternFlow.plans'),
            };
        // ---Navigation group: Reports ---
        case Types.NavigationLinkIds.LISTS:
            return {
                title: i18next.t('navigationV2.titles.lists'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.lists'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.lists'),
                },
            };
        case Types.NavigationLinkIds.LISTS_FILTER:
            return {
                title: i18next.t('list.newList'),
            };
        case Types.NavigationLinkIds.HEAD_COUNT_REPORT:
            return {
                title: i18next.t('navigationV2.titles.headcount'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.headcount'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.headcount'),
                },
            };

        case Types.NavigationLinkIds.OCCUPANCY_REPORT:
            return {
                title: i18next.t('navigationV2.titles.occupancyReport'),
                topBarTitle: i18next.t('navigationV2.titles.occupancyReport'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.occupancy'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.occupancy'),
                },
            };
        case Types.NavigationLinkIds.REGISTRATION:
            return {
                title: i18next.t('navigationV2.titles.occupancy'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.occupancy'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.occupancy'),
                },
            };
        case Types.NavigationLinkIds.FAMLY_REPORT:
            return {
                title: i18next.t('navigationV2.titles.famlyReport'), // TODO: Remember white labeling when translating
                topBarTitle: i18next.t('navigationV2.titles.famlyReport'), // TODO: Remember white labeling when translating
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.famlyReport'),
                },
            };
        case Types.NavigationLinkIds.ORG_BALANCE_REPORT:
            return {
                title: i18next.t('navigationV2.titles.balanceReport'),
                topBarTitle: i18next.t('navigationV2.titles.balanceReport'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.orgBalanceReport'),
                },
            };
        case Types.NavigationLinkIds.FAMLY_INSIGHTS:
            return {
                title: i18next.t('navigationV2.titles.insights'),
                topBarTitle: i18next.t('navigationV2.titles.insights'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.famlyInsights'),
                },
            };
        case Types.NavigationLinkIds.ORG_DASHBOARD:
            return {
                title: i18next.t('navigationV2.titles.dashboard'),
                topBarTitle: i18next.t('navigationV2.titles.dashboard'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.orgDashboard'),
                },
            };
        // --- Navigation group: Store ---
        case Types.NavigationLinkIds.FEATURE_STORE:
        case Types.NavigationLinkIds.FAMLY_STORE_FEATURES:
            return {
                title: i18next.t('famlyStore.navigationLinks.features'), // TODO: Remember white labeling when translating
                mobileTitle: i18next.t('famlyStore.navigationLinks.featuresAndPackages'), // TODO: Remember white labeling when translating
                topBarTitle: i18next.t('famlyStore.navigationLinks.features'), // TODO: Remember white labeling when translating
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.famlyStore'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.famlyStore'),
                },
            };
        case Types.NavigationLinkIds.FAMLY_STORE_PARTNERS:
            return {
                title: i18next.t('famlyStore.navigationLinks.perks'), // TODO: Remember white labeling when translating
                topBarTitle: i18next.t('famlyStore.navigationLinks.perks'), // TODO: Remember white labeling when translating
                pageDescription: {
                    description: i18next.t('famlyStore.navigationLinks.exclusiveOffers'),
                    helpArticleHref: 'https://help.famly.co/en/articles/6397853-famly-perks',
                },
            };
        case Types.NavigationLinkIds.FAMLY_STORE_TEMPLATES:
            return {
                title: i18next.t('famlyStore.navigationLinks.freeTemplates'),
                topBarTitle: i18next.t('famlyStore.navigationLinks.freeTemplates'),
            };
        case Types.NavigationLinkIds.FAMLY_STORE_EMBED_PACKAGE_PRICING_PAGE:
            // This is only a temporary page and will only be shown to users on the new UK freemium package so there is no need for introducing
            // new translation keys in all languages here.
            return {
                title: needsTranslations(TranslationDomain.FamlyStorePackagePricingPage, 'Packages'),
                topBarTitle: needsTranslations(TranslationDomain.FamlyStorePackagePricingPage, 'Famly Store'),
                pageDescription: {
                    description: needsTranslations(TranslationDomain.FamlyStorePackagePricingPage, 'Package Pricing'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.famlyStore'),
                },
            };
        // --- Navigation group: Settings ---
        case Types.NavigationLinkIds.SETTINGS:
            return {
                title: i18next.t('navigationV2.titles.settings'),
            };
        case Types.NavigationLinkIds.ACCOUNT_SETTINGS:
            return {
                title: i18next.t('navigationV2.titles.accountSettings'),
            };
        case Types.NavigationLinkIds.ACCOUNTING_SETTINGS:
            return {
                title: i18next.t('navigationV2.titles.getStartedAccounting'),
            };
        case Types.NavigationLinkIds.GET_STARTED:
            return {
                title: i18next.t('navigationV2.titles.getStarted'),
            };
        case Types.NavigationLinkIds.NOTIFICATIONS:
            return {
                title: i18next.t('navigationV2.titles.notifications'),
            };
        case Types.NavigationLinkIds.INBOX:
            return {
                title: i18next.t('navigationV2.titles.messages'),
            };
        // --- Child profile ---
        case Types.NavigationLinkIds.CHILD_PROFILE_ACTIVITY:
        case Types.NavigationLinkIds.CHILD_PROFILE_ABOUT:
        case Types.NavigationLinkIds.CHILD_PROFILE_SLEEP:
        case Types.NavigationLinkIds.CHILD_PROFILE_INSTITUTION_INFO:
            return {
                title: i18next.t('navigationV2.titles.childProfile'),
            };
        case Types.NavigationLinkIds.CHILD_PROFILE_LEAVE:
        case Types.NavigationLinkIds.CHILD_PROFILE_PHOTOS:
        case Types.NavigationLinkIds.CHILD_PROFILE_PLANS:
        case Types.NavigationLinkIds.CHILD_PROFILE_BILLING:
        case Types.NavigationLinkIds.CHILD_PROFILE_RELATIONS:
        case Types.NavigationLinkIds.CHILD_PROFILE_FAMILY:
        case Types.NavigationLinkIds.CHILD_PROFILE_PLANS_AND_INVOICES:
        case Types.NavigationLinkIds.CHILD_PROFILE_NOTES:
        case Types.NavigationLinkIds.CHILD_PROFILE_NOTE:
        case Types.NavigationLinkIds.CHILD_PROFILE_LEARNING_JOURNEY:
        case Types.NavigationLinkIds.CHILD_PROFILE_LEARNING_JOURNEY_OBSERVATION:
        case Types.NavigationLinkIds.CHILD_PROFILE_LEARNING_JOURNEY_DETAILS:
        case Types.NavigationLinkIds.CHILD_PROFILE_ACCIDENT_INCIDENT_REPORTS:
        case Types.NavigationLinkIds.CHILD_PROFILE_SAFEGUARDING:
        case Types.NavigationLinkIds.CHILD_PROFILE_PRINT_PREVIEW:
            return {
                title: i18next.t('navigationV2.titles.childProfile'),
            };
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_DATA:
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_ABOUT:
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_LEAVE:
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_RELATIONS:
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_CALENDAR:
            return {
                title: i18next.t('navigationV2.titles.employeeProfile'),
            };
        case Types.NavigationLinkIds.PARENT_BOOKING_SETTINGS:
            return {
                title: i18next.t('navigationV2.titles.getStartedParentBookingSettings'),
            };
        case Types.NavigationLinkIds.NO_ACCESS:
            return {
                title: i18next.t('navigationV2.noAccess'),
            };
        case Types.NavigationLinkIds.FINANCE_ONBOARDING:
            return {
                title: i18next.t('financeOnboarding.navigationLink.invoicingBasics'),
                topBarTitle: i18next.t('financeOnboarding.navigationLink.invoicingBasics'),
            };
        case Types.NavigationLinkIds.MOBILE_MENU:
        case Types.NavigationLinkIds.MOBILE_MENU_NAVIGATION_GROUP: {
            return {
                title: i18next.t('menu.menu'),
            };
        }
        case Types.NavigationLinkIds.SUBSCRIPTION:
            return {
                title: i18next.t('menu.subscription'),
                topBarTitle: i18next.t('menu.subscription'),
            };

        case Types.NavigationLinkIds.KINDER_CONNECT:
        case Types.NavigationLinkIds.KINDER_CONNECT_EXPORT:
        case Types.NavigationLinkIds.KINDER_CONNECT_LINKING:
            return {
                title: i18next.t('navigationV2.titles.kinderConnect'),
                pageDescription: {
                    description: i18next.t('navigationV2.descriptions.kinderConnect'),
                    helpArticleHref: i18next.t('navigationV2.helpArticleHrefs.kinderConnect'),
                },
            };

        case Types.NavigationLinkIds.REPORTS:
            return {
                title: i18next.t('navigationV2.titles.reports'),
            };

        case Types.NavigationLinkIds.NEW_REPORT:
        case Types.NavigationLinkIds.CREATE_NEW_REPORT:
        case Types.NavigationLinkIds.CREATE_NEW_REPORT_FROM_DATASET:
        case Types.NavigationLinkIds.CREATE_NEW_REPORT_FROM_REPORT_TEMPLATE:
            return {
                title: i18next.t('navigationV2.titles.reports'), // TODO
            };

        case Types.NavigationLinkIds.REPORT_CATEGORY:
            return {
                title: i18next.t('navigationV2.titles.reportCategory'),
            };

        case Types.NavigationLinkIds.REPORT:
            return {
                title: i18next.t('navigationV2.titles.report'),
            };

        case Types.NavigationLinkIds.REPORT_LISTS:
        case Types.NavigationLinkIds.REPORT_LISTS_FILTER:
            return {
                title: i18next.t('navigationV2.titles.lists'),
            };

        case Types.NavigationLinkIds.REPORT_FAMLY_INSIGHTS:
            return {
                title: i18next.t('navigationV2.titles.insights'),
            };

        case Types.NavigationLinkIds.FINANCE_REPORTS:
            return {
                title: i18next.t('navigationV2.titles.reports'),
            };

        case Types.NavigationLinkIds.FINANCE_REPORT:
            return {
                title: i18next.t('navigationV2.titles.financeReport'),
            };

        case Types.NavigationLinkIds.LEARNING_REPORTS:
            return {
                title: i18next.t('navigationV2.titles.reports'),
            };

        case Types.NavigationLinkIds.LEARNING_REPORT:
            return {
                title: i18next.t('navigationV2.titles.learningReport'),
            };
        case Types.NavigationLinkIds.ATTENDANCE_REPORTS:
            return {
                title: i18next.t('navigationV2.titles.reports'),
            };
        case Types.NavigationLinkIds.REPORTS_ATTENDANCE_REPORT:
            return {
                title: i18next.t('navigationV2.titles.reportsAttendanceReport'),
            };
        case Types.NavigationLinkIds.KIVAN_IMPORT:
            return {
                title: i18next.t('react.navigationV2.config.translations.json.kivanImport'),
            };
        case Types.NavigationLinkIds.ATTENDANCE_LANDING_PAGE:
        case Types.NavigationLinkIds.CHILDREN_LANDING_PAGE:
        case Types.NavigationLinkIds.LEARNING_LANDING_PAGE:
        case Types.NavigationLinkIds.FINANCE_LANDING_PAGE:
            return {
                title: needsTranslations(TranslationDomain.LandingPages, 'Learn more'),
            };
        case Types.NavigationLinkIds.HEALTH_SCREENING_STATEMENTS:
            return {
                title: i18next.t('manageCheckinStatements.title'),
            };
        default:
            exhaustiveCheck(navigationLinkId);
            // We should never get here because of the `exhaustiveCheck`
            return {
                title: '',
            };
    }
};

interface RouteConfig {
    emberRoute: string;
    path: string;
}

export type NavigationRouteKey = 'topLevelRoute' | 'institutionRoute' | 'organizationRoute' | 'customEntityRoutes';

/**
 * NavigationLinkRouteConfig must contain at least one of the NavigationIdRouteConfigs
 * and may optionally contain a config for custom entities
 */
export type NavigationLinkRouteConfig = AtLeastOne<{
    topLevelRoute: RouteConfig;
    institutionRoute: RouteConfig;
    organizationRoute: RouteConfig;
}> & {
    customEntityRoutes?: Array<RouteConfig & {id: Types.CustomEntityIds}>;
};

export const getNavigationLinkRouteConfig = (navigationLinkId: Types.NavigationLinkIds): NavigationLinkRouteConfig => {
    const institutionEmberIdent = `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.institution.emberIdent}`;
    const institutionPath = `${RouteConfig.account.path}${RouteConfig.account.subRoutes.institution.path}`;
    const organizationEmberIdent = `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.organization.emberIdent}`;
    const organizationPath = `${RouteConfig.account.path}${RouteConfig.account.subRoutes.organization.path}`;

    switch (navigationLinkId) {
        // --- Navigation group: Get started ---
        case Types.NavigationLinkIds.NURSERY_INFO:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.info.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.info.path}`,
                },
            };
        case Types.NavigationLinkIds.ROOMS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageGroups.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.manageGroups.path}`,
                },
            };
        case Types.NavigationLinkIds.MANAGE_GROUP_RULES:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageGroupRules.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.manageGroupRules.path}`,
                },
            };
        case Types.NavigationLinkIds.GET_STARTED_CHILDREN:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageChildren.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.manageChildren.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_IMPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childImport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childImport.path}`,
                },
            };
        case Types.NavigationLinkIds.GET_STARTED_STAFF:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFF_ROLES:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.subRoutes.roles.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.path}${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.subRoutes.roles.emberIdent}`,
                },
            };
        case Types.NavigationLinkIds.STAFF_PERMISSIONS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.subRoutes.permissions.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.path}${RouteConfig.account.subRoutes.institution.subRoutes.manageEmployeeRights.subRoutes.permissions.path}`,
                },
            };
        case Types.NavigationLinkIds.INVOICING_PROFILES:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.path}`,
                },
            };
        case Types.NavigationLinkIds.AUTOMATIC_INVOICING_CONFIG:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.automaticInvoicing.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.automaticInvoicing.path}`,
                },
            };
        case Types.NavigationLinkIds.FINANCE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.finance.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.finance.path}`,
                },
            };
        case Types.NavigationLinkIds.ATTENDANCE_SCHEDULES:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.subRoutes.attendanceSchedules.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.path}${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.subRoutes.attendanceSchedules.path}`,
                },
            };
        case Types.NavigationLinkIds.FUNDING_GRANTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.subRoutes.fundingGrants.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.path}${RouteConfig.account.subRoutes.institution.subRoutes.billingProfiles.subRoutes.fundingGrants.path}`,
                },
            };
        case Types.NavigationLinkIds.PRICING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.itemsAndPricing.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.itemsAndPricing.path}`,
                },
            };
        case Types.NavigationLinkIds.DISCOUNT_SETTINGS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.discountSettings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.discountSettings.path}`,
                },
            };
        case Types.NavigationLinkIds.GET_STARTED_PLANS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.managePlans.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.managePlans.path}`,
                },
            };
        case Types.NavigationLinkIds.GET_STARTED_FAMLY_PAY:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyPay.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.famlyPay.path}`,
                },
            };

        case Types.NavigationLinkIds.GET_STARTED_FAMLY_PAY_SETTINGS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.paymentsSettings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.paymentsSettings.path}`,
                },
            };
        case Types.NavigationLinkIds.INVOICE_LAYOUT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.invoiceSettings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.invoiceSettings.path}`,
                },
            };
        // --- Navigation group: Home ---
        case Types.NavigationLinkIds.OVERVIEW:
            return {
                organizationRoute: {
                    emberRoute: organizationEmberIdent,
                    path: organizationPath,
                },
                institutionRoute: {
                    emberRoute: institutionEmberIdent,
                    path: institutionPath,
                },
            };
        case Types.NavigationLinkIds.HOME_CUSTOMIZATION:
            return {
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.homeCustomization.emberIdent}`,
                    path: withPath('/account/organization/:organizationId/customization'),
                },
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.homeCustomization.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/customization'),
                },
            };
        case Types.NavigationLinkIds.CHILDREN_AND_STAFF:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childrenAndStaff.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childrenAndStaff.path}`,
                },
            };
        case Types.NavigationLinkIds.ORGANIZATION_SETTINGS:
            return {
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.settings.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.settings.path}`,
                },
            };
        case Types.NavigationLinkIds.OVERVIEW_REPORTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.lists.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.lists.path}`,
                },
            };
        case Types.NavigationLinkIds.NEWS_FEED:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.feed.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.feed.path}`,
                },
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.feed.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.feed.path}`,
                },
                customEntityRoutes: [
                    {
                        id: Types.CustomEntityIds.NEWS_FEED_MY_NEWS,
                        emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.feed.emberIdent}.${RouteConfig.account.subRoutes.feed.subRoutes.me.emberIdent}`,
                        path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.feed.path}${RouteConfig.account.subRoutes.feed.subRoutes.me.path}`,
                    },
                    {
                        id: Types.CustomEntityIds.NEWS_FEED_BOOKMARKS,
                        emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.feed.emberIdent}.${RouteConfig.account.subRoutes.feed.subRoutes.bookmarks.emberIdent}`,
                        path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.feed.path}${RouteConfig.account.subRoutes.feed.subRoutes.bookmarks.path}`,
                    },
                ],
            };
        case Types.NavigationLinkIds.NEWS_FEED_SINGLE_POST:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.posts.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.posts.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILDREN:
        case Types.NavigationLinkIds.CHILDREN_OVERVIEW:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.children.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.children.path}`,
                },
            };
        case Types.NavigationLinkIds.KIVAN_IMPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.kivanImport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.kivanImport.path}`,
                },
            };
        case Types.NavigationLinkIds.PARENTAL_PERMISSIONS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.parentalPermissions.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.parentalPermissions.path}`,
                },
            };
        case Types.NavigationLinkIds.PARENTAL_PERMISSIONS_CHECK_PERMISSIONS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.parentalPermissions.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.parentalPermissions.subRoutes.matrix.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.parentalPermissions.path}${RouteConfig.account.subRoutes.institution.subRoutes.parentalPermissions.subRoutes.matrix.path}`,
                },
            };
        case Types.NavigationLinkIds.VACATION_PLANS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.vacationPlanning.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.vacationPlanning.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFF:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staff.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staff.path}`,
                },
            };
        case Types.NavigationLinkIds.DOCUMENTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.files.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.files.path}`,
                },
            };
        case Types.NavigationLinkIds.PARENT_DOCUMENTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.files.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.files.subRoutes.parentDocuments.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/files/parentDocuments'),
                },
            };
        case Types.NavigationLinkIds.CALENDAR:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.calendar.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.calendar.path}`,
                },
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.calendar.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.calendar.path}`,
                },
                customEntityRoutes: [
                    {
                        id: Types.CustomEntityIds.MY_CALENDAR,
                        emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.calendar.emberIdent}.${RouteConfig.account.subRoutes.calendar.subRoutes.me.emberIdent}`,
                        path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.calendar.path}${RouteConfig.account.subRoutes.calendar.subRoutes.me.path}`,
                    },
                ],
            };
        case Types.NavigationLinkIds.CALENDAR_EVENT:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.event.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.event.path}`,
                },
            };
        case Types.NavigationLinkIds.OLD_MEAL_PLANS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.subRoutes.oldVersion.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.path}${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.subRoutes.oldVersion.path}`,
                },
            };
        case Types.NavigationLinkIds.MEALS:
        case Types.NavigationLinkIds.MEAL_PLANS_V3:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.path}`,
                },
            };
        case Types.NavigationLinkIds.MEAL_PLANS_V3_CREATE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.subRoutes.createMealPlan.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.path}${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.subRoutes.createMealPlan.path}`,
                },
            };
        case Types.NavigationLinkIds.MEAL_PLANS_V3_VIEW:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.subRoutes.viewMealPlan.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.path}${RouteConfig.account.subRoutes.institution.subRoutes.mealPlan.subRoutes.viewMealPlan.path}`,
                },
            };
        case Types.NavigationLinkIds.MEAL_SETTINGS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealSettings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.mealSettings.path}`,
                },
            };
        case Types.NavigationLinkIds.MEAL_BOOKINGS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.mealBooking.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.mealBooking.path}`,
                },
            };
        case Types.NavigationLinkIds.BILL_PAYER:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.billPayerV2.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.billPayerV2.path}`,
                },
            };
        case Types.NavigationLinkIds.BILL_PAYERS:
            return {
                institutionRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.balances.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.balances.path}`,
                },
            };
        case Types.NavigationLinkIds.LESSON_PLANS_PARENT:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.lessonPlans.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.lessonPlans.path}`,
                },
            };
        // --- Navigation group: Registers ---
        case Types.NavigationLinkIds.ROOM_PLANNING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.roomPlanning.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.roomPlanning.path}`,
                },
            };
        case Types.NavigationLinkIds.ATTENDANCE_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.attendance.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.attendance.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFFING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFF_RATIO_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffRatioReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffRatioReport.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_ATTENDANCE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.attendance.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.attendance.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_SCHEDULE_V2:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.shiftPlanner.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.shiftPlanner.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFF_ATTENDANCE_DETAILS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.attendanceDetails.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.attendanceDetails.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_HOURS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffHours.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffHours.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_HOURS_V2:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffHoursV2.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffHoursV2.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.staffing.subRoutes.staffHoursV2.emberIdent}`,
                    path: withPath('/account/organization/:organizationId/staffing/staffHoursV2'),
                },
            };

        case Types.NavigationLinkIds.STAFFING_STAFF_LEAVE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffLeave.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffLeave.path}`,
                },
            };
        case Types.NavigationLinkIds.STAFFING_STAFF_LEAVE_V2:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffLeaveV2.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.path}${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffLeaveV2.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.staffing.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.staffing.subRoutes.staffLeaveV2.emberIdent}`,
                    path: withPath('/account/organization/:organizationId/staffing/staffLeaveV2'),
                },
            };

        case Types.NavigationLinkIds.INQUIRIES_V2:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.inquiriesV2.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/inquiries'),
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.inquiriesV2.emberIdent}`,
                    path: withPath('/account/organization/:organizationId/inquiries'),
                },
            };
        case Types.NavigationLinkIds.OCCUPANCY:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.occupancy.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.occupancy.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.occupancy.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.occupancy.path}`,
                },
            };
        case Types.NavigationLinkIds.REGISTRATION:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.occupancyPlanner.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.occupancyPlanner.path}`,
                },
            };
        case Types.NavigationLinkIds.OCCUPANCY_PLANNER:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.occupancyPlanner.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.occupancyPlanner.path}`,
                },
            };

        case Types.NavigationLinkIds.OCCUPANCY_INDEX:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.occupancy.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.occupancy.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.occupancy.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.occupancy.path}`,
                },
            };
        // --- Navigation group: Learning ---
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_OVERVIEW:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_NEXT_STEPS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.nextSteps.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.path}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.nextSteps.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_PROGRESS_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.progressReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.path}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.progressReport.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_COMPARE_COHORTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.cohortCompare.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.path}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.cohortCompare.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_TALLY:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.tally.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.path}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.tally.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_TWO_YEAR_CHECKS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.twoYearChecks.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.path}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.twoYearChecks.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_DEVELOPMENT_GROUP_OBSERVATION:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.groupObservation.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.path}${RouteConfig.account.subRoutes.institution.subRoutes.childDevelopment.subRoutes.groupObservation.path}`,
                },
            };
        case Types.NavigationLinkIds.CURRICULUM_BUILDER_LIST:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.path}`,
                },
            };
        case Types.NavigationLinkIds.CURRICULUM_BUILDER_CREATE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.subRoutes.create.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.path}${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.subRoutes.create.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.emberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.subRoutes.create.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.path}${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.subRoutes.create.path}`,
                },
            };
        case Types.NavigationLinkIds.CURRICULUM_BUILDER_DUPLICATE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.subRoutes.create.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.subRoutes.create.subRoutes.duplicate.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.path}${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.subRoutes.create.path}${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.subRoutes.create.subRoutes.duplicate.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.emberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.subRoutes.create.emberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.subRoutes.create.subRoutes.duplicate.emberIdent}`,
                    path: `${organizationEmberIdent}${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.path}${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.subRoutes.create.path}${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.subRoutes.create.subRoutes.duplicate.path}`,
                },
            };
        case Types.NavigationLinkIds.CURRICULUM_BUILDER_SHOW:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.subRoutes.show.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.path}${RouteConfig.account.subRoutes.institution.subRoutes.curriculums.subRoutes.show.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.emberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.subRoutes.show.emberIdent}`,
                    path: `${organizationEmberIdent}${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.path}${RouteConfig.account.subRoutes.organization.subRoutes.curriculums.subRoutes.show.path}`,
                },
            };
        case Types.NavigationLinkIds.ACTIVITY_LIBRARY:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.activities.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.activities.path}`,
                },
            };
        case Types.NavigationLinkIds.ACTIVITY_LIBRARY_SINGLE_LIBRARY: {
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.activities.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.activities.subRoutes.library.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.activities.path}${RouteConfig.account.subRoutes.institution.subRoutes.activities.subRoutes.library.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.ACTIVITY_LIBRARY_SINGLE_ACTIVITY: {
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.activities.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.activities.subRoutes.activity.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.activities.path}${RouteConfig.account.subRoutes.institution.subRoutes.activities.subRoutes.activity.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.LESSON_PLANS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.lessonPlans.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.lessonPlans.path}`,
                },
            };
        // --- Navigation group: Safeguarding ---
        case Types.NavigationLinkIds.ACCIDENTS_INCIDENTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.safeguarding.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.safeguarding.path}`,
                },
            };
        case Types.NavigationLinkIds.MEDICATION_FORMS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.medicationForms.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.medicationForms.path}`,
                },
            };
        case Types.NavigationLinkIds.IMMUNIZATION:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.immunizations.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.immunizations.path}`,
                },
            };
        case Types.NavigationLinkIds.IMMUNIZATION_ONBOARDING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.immunizations.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.immunizations.subRoutes.onboarding.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.immunizations.path}${RouteConfig.account.subRoutes.institution.subRoutes.immunizations.subRoutes.onboarding.path}`,
                },
            };
        case Types.NavigationLinkIds.HEAD_COUNT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.headcount.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.headcount.path}`,
                },
            };
        // --- Navigation group: Finance ---
        case Types.NavigationLinkIds.REVENUE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.revenueReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.revenueReport.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.revenueReport.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.revenueReport.path}`,
                },
            };
        case Types.NavigationLinkIds.DEBT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.debtReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.debtReport.path}`,
                },
            };
        case Types.NavigationLinkIds.PAYMENTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.payments.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.payments.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.payments.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.payments.path}`,
                },
            };
        case Types.NavigationLinkIds.INVOICES_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.invoicesReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.invoicesReport.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.invoicesReport.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.invoicesReport.path}`,
                },
            };
        case Types.NavigationLinkIds.BILL_PAYERS_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.billPayersReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.billPayersReport.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.billPayersReport.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.billPayersReport.path}`,
                },
            };
        case Types.NavigationLinkIds.DEPOSITS_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.depositsReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.depositsReport.path}`,
                },
            };
        case Types.NavigationLinkIds.DIRECT_DEBITS_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.directDebitsReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.directDebitsReport.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.directDebitsReport.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.directDebitsReport.path}`,
                },
            };
        case Types.NavigationLinkIds.INVOICING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.batchInvoice.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.batchInvoice.path}`,
                },
            };
        case Types.NavigationLinkIds.INVOICING_REPORTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.financeReports.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.financeReports.path}`,
                },
            };
        case Types.NavigationLinkIds.PUBLIC_FUNDING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.publicFundingReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.publicFundingReport.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.publicFundingReport.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.publicFundingReport.path}`,
                },
            };
        case Types.NavigationLinkIds.FUNDING_SETTINGS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.fundingSettings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.fundingSettings.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_BASED_FUNDING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childBasedFundingReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.childBasedFundingReport.path}`,
                },
            };
        case Types.NavigationLinkIds.FAMLY_PAY:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyPay.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.famlyPay.path}`,
                },
            };
        case Types.NavigationLinkIds.BULK_IMPORT_PAYMENT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.bulkImportPayment.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.bulkImportPayment.path}`,
                },
            };
        case Types.NavigationLinkIds.BULK_IMPORT_PAYMENT_PROTOPAYMENTS_TABLE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.bulkImportPayment.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.bulkImportPayment.subRoutes.protoPayments.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.bulkImportPayment.path}${RouteConfig.account.subRoutes.institution.subRoutes.bulkImportPayment.subRoutes.protoPayments.path}`,
                },
            };
        case Types.NavigationLinkIds.BOOKINGS:
        case Types.NavigationLinkIds.PLANS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.bookings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.bookings.path}`,
                },
            };
        case Types.NavigationLinkIds.PURCHASES:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.bookings.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.bookings.subRoutes.purchases.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.bookings.path}${RouteConfig.account.subRoutes.institution.subRoutes.bookings.subRoutes.purchases.path}`,
                },
            };
        case Types.NavigationLinkIds.BOOKING_PATTERN_BUILDER:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.bookingPatternBuilder.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.bookingPatternBuilder.path}`,
                },
            };
        case Types.NavigationLinkIds.LISTS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.lists.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.lists.path}`,
                },
            };
        case Types.NavigationLinkIds.LISTS_FILTER:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.lists.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.lists.subRoutes.listFilter.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.lists.path}${RouteConfig.account.subRoutes.institution.subRoutes.lists.subRoutes.listFilter.path}`,
                },
            };
        case Types.NavigationLinkIds.HEAD_COUNT_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.headcountReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.headcountReport.path}`,
                },
            };
        case Types.NavigationLinkIds.OCCUPANCY_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.occupancyReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.occupancyReport.path}`,
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.occupancyReport.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.occupancyReport.path}`,
                },
            };
        case Types.NavigationLinkIds.FORMS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.forms.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/forms'),
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.forms.emberIdent}`,
                    path: withPath('/account/organization/:organizationId/forms'),
                },
            };
        case Types.NavigationLinkIds.CRF_NEW_FORM:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.forms.subRoutes.newForm.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/forms/newForm'),
                },
                // The organization does not and should not have a new form route
            };
        case Types.NavigationLinkIds.FORM_SUBMISSION_RESPONSE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.forms.subRoutes.submissionResponse.emberIdent}`,
                    path: withPath(
                        '/account/institution/:institutionId/forms/:formId/submissionResponse/:submissionId',
                    ),
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.forms.subRoutes.submissionResponse.emberIdent}`,
                    path: withPath(
                        '/account/organization/:organizationId/forms/:formId/submissionResponse/:submissionId',
                    ),
                },
            };
        case Types.NavigationLinkIds.FORM_BUILDER:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.forms.subRoutes.formBuilder.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/forms/builder/:formId'),
                },
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.forms.subRoutes.formBuilder.emberIdent}`,
                    path: withPath('/account/organization/:organizationId/forms/builder/:formId'),
                },
            };

        case Types.NavigationLinkIds.KINDER_CONNECT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.attendance.subRoutes.kinderConnect.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/attendance/kinderConnect'),
                },
            };

        case Types.NavigationLinkIds.KINDER_CONNECT_LINKING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.attendance.subRoutes.kinderConnect.subRoutes.month.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/attendance/kinderConnect/:yearMonth'),
                },
            };

        case Types.NavigationLinkIds.KINDER_CONNECT_EXPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.attendance.subRoutes.kinderConnect.subRoutes.month.subRoutes.export.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/attendance/kinderConnect/:yearMonth/export'),
                },
            };

        case Types.NavigationLinkIds.FAMLY_REPORT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.appReport.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.appReport.path}`,
                },
            };
        case Types.NavigationLinkIds.ORG_BALANCE_REPORT:
            return {
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.balanceReport.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.balanceReport.path}`,
                },
            };
        case Types.NavigationLinkIds.FAMLY_INSIGHTS:
            return {
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.insights.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.insights.path}`,
                },
            };
        case Types.NavigationLinkIds.ORG_DASHBOARD:
            return {
                organizationRoute: {
                    emberRoute: `${organizationEmberIdent}.${RouteConfig.account.subRoutes.organization.subRoutes.dashboard.emberIdent}`,
                    path: `${organizationPath}${RouteConfig.account.subRoutes.organization.subRoutes.dashboard.path}`,
                },
            };
        // --- Navigation group: Settings ---
        case Types.NavigationLinkIds.SETTINGS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.settings.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.settings.path}`,
                },
            };
        case Types.NavigationLinkIds.ACCOUNT_SETTINGS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.accountSettings.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.accountSettings.path}`,
                },
            };
        case Types.NavigationLinkIds.ACCOUNTING_SETTINGS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.accountingSettings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.accountingSettings.path}`,
                },
            };
        case Types.NavigationLinkIds.GET_STARTED:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.institutionSettings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.institutionSettings.path}`,
                },
            };
        // --- Navigation group: Store ---
        case Types.NavigationLinkIds.FEATURE_STORE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.subRoutes.featureStore.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.path}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.subRoutes.featureStore.path}`,
                },
            };
        case Types.NavigationLinkIds.FAMLY_STORE_FEATURES:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.path}`,
                },
            };
        case Types.NavigationLinkIds.FAMLY_STORE_PARTNERS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.subRoutes.partners.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.path}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.subRoutes.partners.path}`,
                },
            };
        case Types.NavigationLinkIds.FAMLY_STORE_TEMPLATES:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.subRoutes.templates.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.path}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.subRoutes.templates.path}`,
                },
            };
        case Types.NavigationLinkIds.FAMLY_STORE_EMBED_PACKAGE_PRICING_PAGE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.subRoutes.packagePricing.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.path}${RouteConfig.account.subRoutes.institution.subRoutes.famlyStore.subRoutes.packagePricing.path}`,
                },
            };
        case Types.NavigationLinkIds.NOTIFICATIONS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.notifications.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.notifications.path}`,
                },
            };
        case Types.NavigationLinkIds.INBOX:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.inbox.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.inbox.path}`,
                },
            };

        // --- Child profile ---
        case Types.NavigationLinkIds.CHILD_PROFILE_ACTIVITY: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.activity.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.activity.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_ABOUT: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.about.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.about.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_SLEEP: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.sleep.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.sleep.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_INSTITUTION_INFO: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.institutionInfo.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.institutionInfo.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_LEAVE: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.leave.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.leave.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_PHOTOS: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.photos.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.photos.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_PLANS: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfilePlans.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfilePlans.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_BILLING: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileBilling.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileBilling.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_RELATIONS: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileRelations.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileRelations.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_FAMILY: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.family.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.family.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_PLANS_AND_INVOICES: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.plansAndInvoices.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.plansAndInvoices.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.CHILD_PROFILE_NOTES:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileNotes.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileNotes.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_PROFILE_NOTE:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileNotes.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileNotes.subRoutes.note.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileNotes.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileNotes.subRoutes.note.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_PROFILE_LEARNING_JOURNEY:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileLearningJourney.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileLearningJourney.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_PROFILE_LEARNING_JOURNEY_OBSERVATION:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileLearningJourney.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileLearningJourney.subRoutes.observation.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileLearningJourney.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileLearningJourney.subRoutes.observation.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_PROFILE_LEARNING_JOURNEY_DETAILS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileLearningJourneyDetails.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileLearningJourneyDetails.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_PROFILE_ACCIDENT_INCIDENT_REPORTS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileAccidentIncidentReports.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.childProfileAccidentIncidentReports.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_PROFILE_SAFEGUARDING:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.safeguarding.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.safeguarding.path}`,
                },
            };
        case Types.NavigationLinkIds.CHILD_PROFILE_PRINT_PREVIEW:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.childProfile.emberIdent}.${RouteConfig.account.subRoutes.childProfile.subRoutes.printPreview.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.childProfile.path}${RouteConfig.account.subRoutes.childProfile.subRoutes.printPreview.path}`,
                },
            };

        // --- Employee profile ---
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_DATA:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.employeeData.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.employee.path}${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.employeeData.path}`,
                },
            };
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_ABOUT:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.about.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.employee.path}${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.about.path}`,
                },
            };
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_LEAVE:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.employeeLeave.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.employee.path}${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.employeeLeave.path}`,
                },
            };
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_RELATIONS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.employeeRelations.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.employee.path}${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.employeeRelations.path}`,
                },
            };
        case Types.NavigationLinkIds.EMPLOYEE_PROFILE_CALENDAR:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.employeeCalendar.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.employee.path}${RouteConfig.account.subRoutes.institution.subRoutes.employee.subRoutes.employeeCalendar.path}`,
                },
            };
        case Types.NavigationLinkIds.PARENT_BOOKING_SETTINGS:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.parentBookingSettings.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.parentBookingSettings.path}`,
                },
            };
        case Types.NavigationLinkIds.FINANCE_ONBOARDING:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.financeOnboarding.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.financeOnboarding.path}`,
                },
            };
        case Types.NavigationLinkIds.NO_ACCESS:
            return {
                topLevelRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.noAccess.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.noAccess.path}`,
                },
            };
        case Types.NavigationLinkIds.MOBILE_MENU: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.navigationV2MobileMenu.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.navigationV2MobileMenu.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.MOBILE_MENU_NAVIGATION_GROUP: {
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.navigationV2MobileMenu.emberIdent}.${RouteConfig.account.subRoutes.navigationV2MobileMenu.subRoutes.navigationGroup.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.navigationV2MobileMenu.path}${RouteConfig.account.subRoutes.navigationV2MobileMenu.subRoutes.navigationGroup.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.ATTENDANCE_STATISTICS: {
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.attendanceStatistics.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.attendanceStatistics.path}`,
                },
            };
        }
        case Types.NavigationLinkIds.BULK_PATTERN_FLOW:
            return {
                institutionRoute: {
                    emberRoute: `${institutionEmberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.bulkPatternFlow.emberIdent}`,
                    path: `${institutionPath}${RouteConfig.account.subRoutes.institution.subRoutes.bulkPatternFlow.path}`,
                },
            };
        case Types.NavigationLinkIds.SUBSCRIPTION:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.subscription.emberIdent}`,
                    path: `${RouteConfig.account.path}${RouteConfig.account.subRoutes.subscription.path}`,
                },
            };

        case Types.NavigationLinkIds.REPORTS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}`,
                    path: withPath('/account/reports'),
                },
            };
        case Types.NavigationLinkIds.NEW_REPORT:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.report.emberIdent}`,
                    path: withPath('/account/reports/report/:reportId'),
                },
            };
        case Types.NavigationLinkIds.CREATE_NEW_REPORT:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.new.emberIdent}`,
                    path: withPath('/account/reports/new'),
                },
            };
        case Types.NavigationLinkIds.CREATE_NEW_REPORT_FROM_DATASET:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.new.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.new.subRoutes.fromDataset.emberIdent}`,
                    path: withPath('/account/reports/new/fromDataset/:datasetId'),
                },
            };
        case Types.NavigationLinkIds.CREATE_NEW_REPORT_FROM_REPORT_TEMPLATE:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.new.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.new.subRoutes.fromReportTemplate.emberIdent}`,
                    path: withPath('/account/reports/new/fromReportTemplate/:reportTemplateId'),
                },
            };

        case Types.NavigationLinkIds.REPORT_CATEGORY:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.category.emberIdent}`,
                    path: withPath('/account/reports/categories/:categoryId'),
                },
            };

        case Types.NavigationLinkIds.REPORT:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.category.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.category.subRoutes.report.emberIdent}`,
                    path: withPath('/account/reports/categories/:categoryId/reports/:reportId'),
                },
            };

        case Types.NavigationLinkIds.REPORT_LISTS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.lists.emberIdent}`,
                    path: withPath('/account/reports/lists'),
                },
            };

        case Types.NavigationLinkIds.REPORT_LISTS_FILTER:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.lists.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.lists.subRoutes.listFilter.emberIdent}`,
                    path: withPath('/account/reports/lists/list-filter'),
                },
            };
        case Types.NavigationLinkIds.REPORT_FAMLY_INSIGHTS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.reports.emberIdent}.${RouteConfig.account.subRoutes.reports.subRoutes.famlyInsights.emberIdent}`,
                    path: withPath('/account/reports/famlyInsights'),
                },
            };
        case Types.NavigationLinkIds.FINANCE_REPORTS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.financeReports.emberIdent}`,
                    path: withPath('/account/financeReports'),
                },
            };
        case Types.NavigationLinkIds.FINANCE_REPORT:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.financeReports.emberIdent}.${RouteConfig.account.subRoutes.financeReports.subRoutes.report.emberIdent}`,
                    path: withPath('/account/financeReports/:reportId'),
                },
            };
        case Types.NavigationLinkIds.LEARNING_REPORTS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.learningReports.emberIdent}`,
                    path: withPath('/account/learningReports'),
                },
            };
        case Types.NavigationLinkIds.LEARNING_REPORT:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.learningReports.emberIdent}.${RouteConfig.account.subRoutes.learningReports.subRoutes.report.emberIdent}`,
                    path: withPath('/account/learningReports/:reportId'),
                },
            };
        case Types.NavigationLinkIds.ATTENDANCE_REPORTS:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.attendanceReports.emberIdent}`,
                    path: withPath('/account/attendanceReports'),
                },
            };
        case Types.NavigationLinkIds.REPORTS_ATTENDANCE_REPORT:
            return {
                topLevelRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.attendanceReports.emberIdent}.${RouteConfig.account.subRoutes.attendanceReports.subRoutes.report.emberIdent}`,
                    path: withPath('/account/attendanceReports/:reportId'),
                },
            };
        case Types.NavigationLinkIds.FINANCE_LANDING_PAGE:
            return {
                institutionRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.institution.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.financeLandingPage.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/financeLandingPage'),
                },
            };
        case Types.NavigationLinkIds.ATTENDANCE_LANDING_PAGE:
            return {
                institutionRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.institution.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.attendanceLandingPage.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/attendanceLandingPage'),
                },
            };
        case Types.NavigationLinkIds.LEARNING_LANDING_PAGE:
            return {
                institutionRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.institution.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.learningLandingPage.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/learningLandingPage'),
                },
            };
        case Types.NavigationLinkIds.CHILDREN_LANDING_PAGE:
            return {
                institutionRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.institution.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.childrenLandingPage.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/childrenLandingPage'),
                },
            };
        case Types.NavigationLinkIds.HEALTH_SCREENING_STATEMENTS:
            return {
                institutionRoute: {
                    emberRoute: `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.institution.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.manageCheckinStatements.emberIdent}`,
                    path: withPath('/account/institution/:institutionId/manageCheckinStatements'),
                },
            };
        default:
            exhaustiveCheck(navigationLinkId);
            // We should never get here
            return {topLevelRoute: {emberRoute: '', path: ''}};
    }
};
