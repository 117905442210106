import React from 'react';
import Stack, {type BoxProps} from '@mui/material/Box';
import type CSSType from 'csstype';

import {exhaustiveCheck} from 'modern-famly/util';
import {useDataProps, type DataProps} from 'modern-famly/components/util';

import {Text} from '../text';
import {type ListBreakpoint, useListMediaQuery} from './use-list-media-query';
import {ClassNames} from './shared';
import {Icon, type IconName} from '../icon';

export type SortDirection = 'asc' | 'desc';

type Sorting = {
    direction: SortDirection;
    isActive?: boolean;
};

export type ListHeaderItemProps = {
    title?: string;
    textAlign?: CSSType.Property.TextAlign;

    /**
     * @default 'tabletPortrait'
     */
    displayFrom?: ListBreakpoint;

    px?: BoxProps['px'];

    /**
     * Adds a sorting indicator next to the title
     */
    sorting?: Sorting;

    /**
     * Callback when the header item is clicked
     * Useful for toggling sorting direction
     */
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
} & DataProps;

export const ListHeaderItem = (props: ListHeaderItemProps) => {
    const {title, displayFrom = 'tabletPortrait'} = props;

    const dataProps = useDataProps(props);

    const isVisible = useListMediaQuery(displayFrom);

    return (
        <Stack
            className={ClassNames.ListHeader}
            display={isVisible ? 'inline-flex' : 'none'}
            px={props.px ?? 4}
            onClick={props.onClick}
            alignItems="center"
            flexDirection="row"
            justifyContent={props.textAlign === 'right' ? 'flex-end' : 'flex-start'}
            gap={2}
            sx={{
                cursor: props.onClick ? 'pointer' : 'default',
            }}
            {...dataProps}
        >
            <Text variant="body-small" emphasized>
                {title}
            </Text>
            {props.sorting ? <SortIcon sorting={props.sorting} /> : null}
        </Stack>
    );
};

const getSortIcon = ({direction, isActive}: Sorting): IconName => {
    if (!isActive) {
        return 'swap_vert';
    }

    switch (direction) {
        case 'asc':
            return 'arrow_upward_alt';
        case 'desc':
            return 'arrow_downward_alt';
        default:
            exhaustiveCheck(direction);
            return 'swap_vert';
    }
};

const SortIcon = (props: {sorting: Sorting}) => {
    return (
        <Icon
            size={20}
            color={props.sorting.isActive ? 'n400' : 'n200'}
            name={getSortIcon(props.sorting)}
            data-e2e-class={
                props.sorting.isActive ? 'list-header-item-sorting-active' : 'list-header-item-sorting-inactive'
            }
        />
    );
};
