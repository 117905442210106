import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';

import {Icon, type IconName} from 'modern-famly/components/data-display/icon';
import {Text} from 'modern-famly/components/data-display/text';
import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {exhaustiveCheck} from 'modern-famly/util';
import {type ColorKey} from 'modern-famly/theming';
import {extractSystemStyles, type AlignmentProps} from 'modern-famly/system/system-props';

export type Severity = 'info' | 'neutral' | 'error' | 'warning' | 'success';

export type StatusPillProps = {
    /**
     * The label of the pill.
     */
    text: string;

    /**
     * Controls the size of the StatusPill.
     * Defaults to 'regular'
     */
    size?: 'regular' | 'compact';

    /**
     * 'contained' adds a background to the StatusPill.
     * Defaults to 'contained'
     */
    variant?: 'contained' | 'transparent';

    /**
     * Controls the color and background color of the StatusPill.
     */
    severity: Severity;

    /**
     * Whether to show a dot on the left side.
     */
    showDot?: boolean;

    /**
     * An icon displayed in the left side of the status pill.
     * Overrides the dot determined by the `showDot` prop.
     */
    icon?: IconName;
} & DataProps &
    AlignmentProps;

export const StatusPill = (props: StatusPillProps) => {
    const {text, size = 'regular', variant = 'contained', severity, showDot, icon} = props;

    const dataProps = useDataProps(props);

    const {sx: alignmentSx} = extractSystemStyles(props);

    const theme = useTheme();

    const backgroundColor = theme.modernFamlyTheme.colorPalette[getBackgroundColor(severity)];
    const foregroundColorKey = getForegroundColor(severity);

    return (
        <Stack
            display="inline-flex"
            direction="row"
            alignItems="center"
            gap={icon ? 1 : 2}
            height={size === 'regular' ? '36px' : '24px'}
            paddingRight={3}
            paddingLeft={!showDot && !icon ? 3 : 2}
            borderRadius="24px"
            bgcolor={variant === 'contained' ? backgroundColor : 'transparent'}
            sx={alignmentSx}
            {...dataProps}
        >
            {showDot && !icon ? <Dot color={foregroundColorKey} /> : null}
            {icon ? <Icon name={icon} size={18} color={foregroundColorKey} /> : null}
            <Text variant="body-small" ellipsis color="n500">
                {text}
            </Text>
        </Stack>
    );
};

type DotProps = {
    color: ColorKey;
};

const Dot = (props: DotProps) => {
    const theme = useTheme();

    return (
        <Box
            height="8px"
            width="8px"
            borderRadius="4px"
            bgcolor={theme.modernFamlyTheme.colorPalette[props.color]}
            data-e2e-class="dot"
        />
    );
};

const getForegroundColor = (severity: Severity): ColorKey => {
    switch (severity) {
        case 'info':
            return 'b400';
        case 'neutral':
            return 'n400';
        case 'error':
            return 'r400';
        case 'warning':
            return 'y400';
        case 'success':
            return 'g400';
        default:
            exhaustiveCheck(severity);
            return 'b400';
    }
};

const getBackgroundColor = (severity: Severity): ColorKey => {
    switch (severity) {
        case 'info':
            return 'b100';
        case 'neutral':
            return 'n100';
        case 'error':
            return 'r100';
        case 'warning':
            return 'y100';
        case 'success':
            return 'g100';
        default:
            exhaustiveCheck(severity);
            return 'b100';
    }
};
