/* eslint-disable no-restricted-imports */
import React, {createContext, useContext, useState, type ReactNode} from 'react';
import {Dialog} from '@famly/mf_modals-dialogs_dialog';
import {extractAriaProps, extractDataProps, type DataProps} from 'modern-famly/components/util';

type ModalDialogItem = {
    /** Unique identifier for the modal/dialog */
    id: string;

    /** Type of overlay - modal or dialog */
    type: 'modal' | 'dialog';

    /** React element to render */
    element: React.ReactElement;
} & DataProps;

type ModalDialogContextType = {
    /** Array of active modal/dialog components */
    modalDialogs: ModalDialogItem[];

    /** Function to display a new modal/dialog */
    showModalDialog: (modalDialog: ModalDialogItem) => void;

    /** Function to remove a modal/dialog by ID */
    hideModalDialog: (id: string) => void;
};

const ModalDialogContext = createContext<ModalDialogContextType | undefined>(undefined);

/**
 * Provider component that manages modal/dialog state
 *
 * @param children - React children nodes
 *
 * @example
 * <ModalDialogProvider>
 *   <App />
 * </ModalDialogProvider>
 */
export const ModalDialogProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [modalDialogs, setModalDialogs] = useState<ModalDialogItem[]>([]);

    const showModalDialog = React.useCallback((modalDialog: ModalDialogItem) => {
        setModalDialogs(prev => [...prev, modalDialog]);
    }, []);

    const hideModalDialog = React.useCallback((id: string) => {
        setModalDialogs(prev => prev.filter(modalDialog => modalDialog.id !== id));
    }, []);

    return (
        <ModalDialogContext.Provider
            value={React.useMemo(
                () => ({modalDialogs, showModalDialog, hideModalDialog}),
                [hideModalDialog, modalDialogs, showModalDialog],
            )}
        >
            {children}
            {modalDialogs.map(modalDialog => (
                <Dialog open key={modalDialog.id} {...extractDataProps(modalDialog)} {...extractAriaProps(modalDialog)}>
                    {modalDialog.element}
                </Dialog>
            ))}
        </ModalDialogContext.Provider>
    );
};
/**
 * Hook to access modal dialog context
 *
 * @returns {ModalDialogContextType} Modal dialog context
 * @throws Error if used outside provider
 *
 * @example
 * const { showModalDialog, hideModalDialog } = useModalsDialogsContext()
 */
export const useModalsDialogsContext = (): ModalDialogContextType => {
    const context = useContext(ModalDialogContext);
    if (!context) {
        throw new Error('useModalsDialogsContext must be used within a ModalProvider');
    }
    return context;
};
